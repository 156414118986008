$white:           #fff;
$black:           #09323A;
$blackclassic:    #47656B;
$gray:            #F7F9FB;
$grayclassic:     #EAF3F5;
$grayclassic2:    #ffffff7a;

$blue:            #16DAf4;//#00bceb;
$blueclassic:     #0FC8EF;// #0AC9EF;
$blueclassic2:    #00beeb;
$blueclassic3:    #81eaff;
$blueclassic4:    #1B79DF;
$dark:            #5b777c;
$darkclassic:     #2C4E55;
$yellow:          #ffb400;
$yellowclassic:   #ffcc4c;
$yellowclassic2:  #FCB504;
$red:             #ff3d67;
$redclassic:      #ff4570;
$redclassic2:     #FD3F67;
$green:           #00d6a2;
$loading:         #9AAFB4;
$loading2:        #F7F9FB !important;
$greenclassic:    #43D8B3;
$greenclassic2:   #82ebd14d;
$graymenu:        #839499;
$standardborder:  #dfe4e5a8;
$standardborder2: #dfe4e55c;
$menuline:        #dfe4e5b3;
$popupborder:     #E8ECED;
$chatine:         #EEF2F3;
$graychatsent:    #F2F6F7;
$violet:          #D867c7;
$violetclassic:   #A14DBA;
$violetteam:      #C26EDC;
$borderproject:   #E8ECED;
$borderdash:      #D5E1E4;
$greenclassic3:   #05D677;
$greenspace:      #BDF1E4;
$taggreen:        #05D6A2;
$shadow:          #CED5DC26;
$shadowsign:      #47656b1a;
$shadowmodal:     #47656b26;
$chatline:        #DBE3E5;
$storyviewed:     #DBEAED;
$dragline:        #ccd6d9a6;
$footerline:      #9AAFB4;
$inputborder:     #D5E1E4D9;
$hover:           #F7F9FB;
$topbar:  78px;
$topbarboxh: 42px;
$topbarboxw: 140px;
$leftbar: 110px;
$rightbar: 290px;
$doublewidth: calc(100% - 50px);
$singlewidth: calc(50% - 37.5px);

$emptyanimation: loadingshadow 1s infinite alternate;

$parallax-perspective : 1 !default;
$parallax-element     : "body" !default;
$parallax-ios         : true !default;
